import React from 'react';
import PropTypes from 'prop-types';

const Radio = ({ label, input, name, meta: { touched, error, warning } }) => {
  return (
    <div>
      <input className="form-check-input" type="radio" id={name} {...input} />
      <label className="form-radio-label" htmlFor="flexCheckChecked">
        {label}
      </label>
      {touched &&
        ((error && <div className="text-danger mt-2">{error}</div>) ||
          (warning && <div className="text-warning mt-2">{warning}</div>))}
    </div>
  );
};

Radio.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  input: PropTypes.any,
  name: PropTypes.string.isRequired,
  meta: PropTypes.any,
};

export default Radio;
