import React from 'react';
import { Switch, BrowserRouter as Router } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';
import { PageNotFound, Policy, Term, Dashboard } from 'pages';
import Routes from './routes';

const RouteContainer = () => {
  return (
    <Router>
      <Switch>
        <Route path={Routes.POLICY} component={Policy} exact={true} />
        <Route path={Routes.TERM} component={Term} exact={true} />
        <Route path={Routes.DASHBOARD} component={Dashboard} exact={true} />
        <Route
          path={Routes.DASHBOARD_FORM}
          component={Dashboard}
          exact={true}
        />
        <Route path="*" component={PageNotFound} />
      </Switch>
    </Router>
  );
};

RouteContainer.propTypes = {
  layout: PropTypes.any,
};

export default RouteContainer;
