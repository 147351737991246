import React from 'react';
import PropTypes from 'prop-types';
import AppStore from 'assets/img/app-store.png';
import GooglePlay from 'assets/img/google-play.png';
import Routes from 'routes/routes';
import './styles.scss';

const Footer = ({ history }) => {
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };
  const redirectToTerm = () => {
    if (history.location.pathname !== Routes.TERM) {
      history.push(Routes.TERM);
    }
  };
  return (
    <footer id="footer">
      <a
        href="#"
        onClick={scrollToTop}
        className="back-to-top-btn btn btn-gradient-primary"
        id="back-to-top"
        style={{ display: 'block' }}
      >
        <span className="triagle"></span>
      </a>
      <div className="container">
        <div className="row text-center">
          <div className="col-lg-12 footer-content">
            Download Our App:
            <br />
            <a
              href="https://play.google.com/store/apps/details?id=com.collnect.coamlottery&pli=1"
              target="_blank"
              alt="collnect-chplay"
              rel="noreferrer"
            >
              <img
                id=""
                className="img-center img-fluid images-app"
                src={GooglePlay}
                alt=""
              />
            </a>
            <a
              href="https://apps.apple.com/vn/app/georgia-gift-cards/id1658808818"
              target="_blank"
              alt="collnect-appstore"
              rel="noreferrer"
            >
              <img
                id=""
                className="img-center img-fluid images-app"
                src={AppStore}
                alt=""
              />
            </a>
            <br />
            Contact Us:{' '}
            <a href="mailto:gagiftcardinfo@collnectsoftware.com">
              gagiftcardinfo@collnectsoftware.com
            </a>
            . | <b>+1-888-755-3535.</b>
          </div>
        </div>
      </div>
      <div className="bg_black">
        <div className="text-center ">
          <div className="text-primary-hover">
            {' '}
            Copyrights{' '}
            <a href="" onClick={redirectToTerm} className="text-body">
              ©2023 CollNect Software
            </a>
            . All rights reserved.{' '}
          </div>
        </div>
      </div>
    </footer>
  );
};

Footer.propTypes = {
  history: PropTypes.any,
};

export default Footer;
