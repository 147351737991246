const isRequired = (value) => {
  return value?.trim() === '' || value === null || value === undefined
    ? 'Required'
    : undefined;
};

const isEmail = (value) =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
    ? 'Invalid email address'
    : undefined;

const isValidPassword = (value) =>
  value?.match('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[!@#$&*~]).{8,}$')
    ? undefined
    : 'Use 8 or more characters with a mix of letters, numbers & symbols';

const isPasswordConfirmed = (value, allValues) =>
  value !== allValues.password ? 'Password does not match' : undefined;

const maxLength = (max) => (value) => {
  return value?.length > max ? `Max length is ${max}` : undefined;
};
// const isRequired = isRequiredWithErrorText();
// const isEmail = isEmailWithErrorText();

export default {
  // isRequiredWithErrorText,
  // isEmailWithErrorText,
  isRequired,
  isEmail,
  isValidPassword,
  isPasswordConfirmed,
  maxLength,
};
