import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import './styles.scss';
import { Spinner } from 'reactstrap';

const TextInput = ({
  label,
  placeholder,
  name,
  className,
  input,
  type,
  errorMessage,
  pattern,
  disabled,
  fetching,
  scanIcon,
  meta: { touched, error, warning },
}) => {
  const classNames = classnames(
    `${label ? 'form-floating mb-3' : ''} omny-textInput`,
    className,
  );
  const formControlClassNames = classnames('form-control', {
    'is-invalid': touched === true && error !== undefined,
  });

  return (
    <div className={classNames}>
      <input
        type={type}
        className={formControlClassNames}
        id={name}
        placeholder={placeholder}
        pattern={pattern}
        disabled={disabled}
        {...input}
      />
      {fetching && <Spinner />}
      {scanIcon && !fetching && scanIcon}
      {label && <label htmlFor={name}>{label}</label>}

      {errorMessage ? (
        <div className="text-danger mt-2">{errorMessage}</div>
      ) : (
        touched &&
        ((error && <div className="text-danger mt-2">{error}</div>) ||
          (warning && <div className="text-warning mt-2">{warning}</div>))
      )}
    </div>
  );
};

TextInput.defaultProps = {
  type: 'text',
  placeholder: 'name@example.com',
};

TextInput.propTypes = {
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  name: PropTypes.string,
  className: PropTypes.string,
  input: PropTypes.any,
  meta: PropTypes.object,
  pattern: PropTypes.string,
  disabled: PropTypes.bool,
  fetching: PropTypes.bool,
  scanIcon: PropTypes.node,
  errorMessage: PropTypes.string,
};

export default TextInput;
