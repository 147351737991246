import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Logo from 'assets/img/logo.svg';
import Routes from 'routes/routes';
import './styles.scss';

const Header = ({ history }) => {
  useEffect(() => {
    window.addEventListener('scroll', isOnTop);
    return () => {
      window.removeEventListener('scroll', isOnTop);
    };
  }, []);

  const isOnTop = () => {
    const header = document.getElementById('navbar-sticky');
    const backToTopBtn = document.getElementById('back-to-top');
    if (
      window.scrollY > 50 &&
      header &&
      !header.classList.contains('nav-sticky')
    ) {
      header.classList.add('nav-sticky');
      if (backToTopBtn) {
        backToTopBtn.style.display = 'block';
      }
    } else if (
      window.scrollY <= 50 &&
      header &&
      header.classList.contains('nav-sticky')
    ) {
      header.classList.remove('nav-sticky');
      if (backToTopBtn) {
        backToTopBtn.style.display = 'none';
      }
    }
  };

  const redirectToHome = () => {
    history.push(Routes.DASHBOARD);
  };

  const redirectToContactForm = () => {
    if (history.location.pathname !== Routes.DASHBOARD) {
      history.replace(Routes.DASHBOARD_FORM);
    }
  };
  return (
    <header className="meta-gift-header">
      <div
        className="navbar navbar-expand-lg fixed-top navbar-custom sticky-dark "
        id="navbar-sticky"
      >
        <div className="container">
          <img
            width="300"
            height="140"
            className="logo"
            src={Logo}
            onClick={redirectToHome}
          />
          <ul className="nav nav-pills justify-content-center">
            <li className="nav-item for-support border-left">
              <span className="nav-link">
                For Support
                <br />
                <b>1-877-777-0314</b>
              </span>
            </li>
            <li className="nav-item display-none">
              <a href="#contactus" className="nav-link">
                {' '}
                <button
                  type="button"
                  className="btn btn-warning btn-lg button-size"
                  onClick={redirectToContactForm}
                >
                  Get Started
                </button>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </header>
  );
};

Header.propTypes = {
  history: PropTypes.any,
};

export default Header;
